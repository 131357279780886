<template>
  <div class="select-cert">
    <div class="banner">
      <img src="../assets/images/lunbo.png">
    </div>
    <div class="cert-form">
      <el-form :model="certForm" :rules="certRule" ref="certFormEl" label-width="100px" label-position="left"
        class="form-body">
        <el-form-item prop="cert_num">
          <span slot="label">证书编号：</span>
          <el-input v-model="certForm.cert_num" placeholder="请输入证书编号"></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <span slot="label">姓&emsp;&emsp;名：</span>
          <el-input v-model="certForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selectCert">立即查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="cert-img-show">

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      certForm: {
        name: '',
        cert_num: ''
      },
      certRule: {
        cert_num: [
          { required: true, message: '请输入证书编号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      },
      selectPicUrl: ''
    }
  },
  methods: {
    selectCert() {
      this.$refs.certFormEl.validate(async (valid) => {
        if (valid) {
          let fd = new FormData()
          // for (let item in this.certForm) {
          //   fd.append(item, this.certForm[item])
          // }
          fd.append('name', this.certForm.name)
          fd.append('cert_num', this.certForm.cert_num)
          let { data } = await axios.post('/index/cert_select', fd)
          if (data.code == 1) {
            this.showCertImg(data.pic)
          } else {
            this.$message.error(data.msg)
          }
        } else {
          return false;
        }
      })

    },
    showCertImg(url) {
      const h = this.$createElement;
      let newUrl = 'http://tot.xlteacher.cn' + url
      this.$alert(`<img src=${newUrl} />`, '', {
        showConfirmButton: false,
        dangerouslyUseHTMLString: true,
        customClass: 'show-cert-img-modal',
        callback: action => {
          this.selectPicUrl = ''
        }
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.select-cert {

  .banner {
    width: 100%;
    height: 235px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      left: 0%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .cert-form {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;

    .form-body {
      width: 350px;
      margin: 0 auto;
    }

  }

  .cert-img-show {
    width: 500px;
  }
}
</style>
<style lang="scss">
.show-cert-img-modal {
  width: 700px;

  .el-message-box__content {
    padding-bottom: 0;
  }
}
</style>
